.CartItem {
	margin-bottom: 1em;
	padding-bottom: .5em;
	border-bottom: 1px solid #e4e4e4;
	justify-content: space-between;
}

.cart-item-product-info {
	width: 70%;
}

.cart-item-link-to-product {
	text-decoration: none;
}

.cart-item-img {
	width: 10em;
	height: 10em;
	margin-right: 1em;
}

.cart-item-title {
	color: black;
	font-weight: none;
	margin: 0;
	margin-bottom: .5em;
}

.cart-item-link-to-product:hover .cart-item-title {
	color: #939393;
}

.options-container {
	margin-bottom: .5em;
}

.options-text {
	color: #663096;
}

.remove-from-cart-btn {
	padding: .5em;
	width: 5em;
}

.cart-item-qty-wrapper {
	margin-right: 2em;
	width: 4em;
}

.cart-item-price-wrapper {
	margin-left: 2em;
	width: 4em;
}

.cart-item-price-txt, .cart-item-quantity {
	color: black;
}

.cart-item-price-val {
	color: #990000;
	font-weight: bold;
	margin-bottom: 0;
	font-size: 1.2em;
}

.listing-cost-each {
	text-align: right;
	margin-top: .2em;
	color: #939393;
	font-size: .9em;

	text-align: right;
}

@media only screen and (max-width: 780px) {
	.CartItem {
		justify-content: center;
	}	
}

@media only screen and (max-width: 770px) {
	.cart-item-product-info {
		justify-content: center;
	}	
}

