@import 'styles/animations.scss';


#Navbar, #header {
  width: 100%;

  background-color: white;

  font-family: 'Raleway', sans-serif;
  border-bottom: 1px solid #e4e4e4;

  z-index: 1;
}

#Navbar {
  // position: fixed;
  // top: 0;
  // left: 0;
}

#header {
  width: 90%;
  margin: 0 auto;
  border-bottom: none;
}

#nav-cart-container, #logo {
  text-decoration: none; 
  align-items: center;
  flex-grow: 1;
}

#logo-img {
  // max-width: 10em;
  max-height: 5em;
}

#logo-heading, .nav-cart-text {
  font-weight: normal;
  color: #545454;
  margin: auto 0;
  margin-left: .5em;
}

#logo-heading {
  font-size: 3em;
}

#cart-img {
  max-width: 3em;
  max-height: 3em;
}

#nav-cart-small-text {
  font-size: .7em;
  color: #939393;
}

#nav-cart-med-text {
  font-size: 1.2em;
}

#nav-cart-container:hover #nav-cart-med-text {
  color: #939393;
}

/*navbar links*/
#navbar-link-container {
  height: 60%;
  margin: auto 0;
  align-items: flex-end;
  flex-grow: 2;
}

#mobile-header {
  border-top: none;
}

@media screen and (max-width: 970px) {
  #Navbar {
    position: static;
  }

  #mobile-header {
    border-top: 1px solid #e4e4e4;
    width: 100%;
  }
  
}

@media screen and (max-width: 475px) {
  #header {
    display: flex;
    justify-content: center;
  }

  #nav-cart-container, #logo {
    flex-grow: 0;
  }

}