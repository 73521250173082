#changelog-items {
	width: 80%;
}

.change-container {
	padding-bottom: 1em; 
	border-bottom: 1px solid #e4e4e4;
}

.change-date {
	width: 8em;
}

.change-date-txt {
	font-size: 1.2em;
}

.change-category-title {
	margin-bottom: .2em;
}

.change-description {
	width: 80%
}

@media screen and (max-width: 600px) {
	#changelog-items {
		width: 90%;
	}

  .change-description {
		width: 100%
	}
}
