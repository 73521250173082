#mobile-navbar-link-container {
  display: none;
}

@media screen and (max-width: 970px) {
  #mobile-navbar-link-container {
    width: 90%;

    margin: 0 auto;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #navbar-link-container {
    display: none;
  }

}