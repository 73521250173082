.HamburgerIcon {
	height: 100%;
	width: 100%;
}

.hamburger-icon-bar {
  width: 35px;
  height: 3px;
  background-color: #393939;
  margin: 6px 0;
  transition: 0.4s;
}

.animate-hamburger-icon .top-hamburger-icon-bar {
  -webkit-transform: rotate(-45deg) translate(-6px, 5px) ;
  transform: rotate(-45deg) translate(-6px, 5px) ;
}

.animate-hamburger-icon .middle-hamburger-icon-bar {
  opacity: 0;
}

.animate-hamburger-icon .bottom-hamburger-icon-bar {
  -webkit-transform: rotate(45deg) translate(-8px, -7px) ;
  transform: rotate(45deg) translate(-8px, -7px) ;
}