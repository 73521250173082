#Docs {
	/*margin-right: 17em;*/
}

@media screen and (max-width: 970px) {
	#Docs {
		margin-right: 0;
	}
}

#doc-content {
	width: 80%;
	margin: 0 auto;
}

@media screen and (max-width: 970px) {
	#doc-content {
		width: 100%;
	}
}

#doc-content img, .doc-preview img {
  display:block;
  margin:auto;
  /*max-width: 80%;*/
  max-height: 50em;
}

.center-text-in-md {
	text-align: center;
}

.doc-preview {
	margin-bottom: 3em;
	border-bottom: 1px solid black;
}

.doc-preview img {
  max-width: 40%;
}

.doc-preview-link {
	color: black;
	text-decoration: none;
}

.doc-preview-header {
  border-bottom: 1px solid transparent;
  transition: border .15s ease;
}

.doc-preview-link:hover .doc-preview-header {
  color: #939393;
  text-decoration: underline;
}

.doc-preview-link:hover .doc-preview-md img {
  box-shadow: 0 0 10px #3f3f3f;
}

.doc-preview-clock-icon {
	display: inline-block;
	margin-left: 0!important;
	margin-right: 0!important;
}

.doc-preview-inline {
	font-size: .8em;
}

.doc-preview-date {
	margin-left: .4em;
}

.doc-preview-read-more {
  flex-grow: 100;
}

.doc-preview-link:hover .doc-preview-read-more {
  color: #939393;
  text-decoration: underline;
}

