#Footer {
	margin: 0 auto;
	/*border-top: 1px solid #a8a8a8;*/
	padding-top: 5em;
	padding-bottom: 2em;
	background-color: #303841;
	color: white;
}

#footer-content {
	width: 80%;
}

.footer-links-container {
	width: 15em;
	margin-bottom: 3em;
}

.footer-links-title {
	margin-bottom: .4em;
	margin-top: 0;
	font-weight: normal;
}

.footer-link {
	text-decoration: none;
}

.footer-link-text {
	margin: .3em 0;
	color: white;
}

.footer-link-text:hover {
	color: #cccccc;
	/*color: #545454;*/
	/*color: black;*/
}

#copyright {
	/*color: black;*/
}

@media only screen and (max-width: 620px) {
  	.footer-links-title, .footer-link {
		text-align: center;
	}
}