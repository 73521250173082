.CartItem {
	margin-bottom: 1em;
	padding-bottom: .5em;
	border-bottom: 1px solid #e4e4e4;
}

.cart-item-container {
	justify-content: space-between;
}

.product-index-container {
	width: 2em;
}

.remove-from-cart-btn {

}

.cart-item-link-to-product {
	text-decoration: none;
}

.cart-item-img {
	max-width: 7em;
	max-height: 7em;
	/*margin-right: 1em;*/
	display:block;
	margin: auto;
	margin-bottom: .2em; 
}

.cart-item-first-row {
	margin-top: 0;
	margin-bottom: .4em;
}

.cart-item-title {
	color: black;
	transition: color .15s ease;
}

.cart-item-link-to-product:hover .cart-item-title {
	color: #939393;
}

.cart-item-column, .cart-item-column-first {
	margin-right: 2em;
	width: 10em;
}

.cart-item-column-first {
	display: flex;
}

.in-stock {
	color: green;
	margin-top: 0;
	margin-bottom: 0.5em;
}

.options-container {

}

.options-text {
	font-size: .9em;
	margin-bottom: 0;
}

.cart-item-price-txt, .cart-item-quantity {
	color: black;
}

.cart-item-price-val {
	color: #990000;
	font-weight: bold;
	margin-bottom: 0;
	font-size: 1.2em;
}

.listing-cost-each {
	text-align: right;
	margin-top: .2em;
	color: #939393;
	font-size: .9em;

	/*right justify*/
	direction: rtl;
	text-align: justify;
	margin-left: @center-justification;
	margin-right: @center-justification;
}

@media only screen and (max-width: 480px) {
	.cart-item-column {
		margin-right: 0em;
	}

	.cart-item-column-first {
		margin-right: 1em;
	}
}
