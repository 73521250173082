@import 'styles/colors.scss';

.Sidebar {
	top: 80px;
  right: 0;

  height: 100%;
  width: 20em;

	position: fixed;
	background-color: #f7f7f7;
}

@media screen and (max-width: 970px) {
  .Sidebar {
  	top: 134px;
  }

}

@media screen and (max-width: 500px) {
	.Sidebar {
  	display: none;
  }
}