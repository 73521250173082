#CustomOrders {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

#custom-order-info {
	margin-left: 2em;
}

.custom-orders-basic-info-title, .custom-orders-basic-info-text {
	margin-bottom: 0;
}

.custom-orders-basic-info-text {
	margin-top: 1.3em; 
	margin-left: .2em;
}

.markdown-container {
	margin-left: 2em;
}

#shop-item-order-now {
	text-decoration: none;
	color: black;
	display: block;
	margin-top: 1em;
	transition: color .15s ease;
	border: black solid;
	padding: .2em;
	text-align: center;
	max-width: 10em;
}

#shop-item-order-now:hover {
	color: #939393;
	border: #939393 solid;

}
