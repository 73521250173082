#CartItems, .cart-item {
	margin: 0 auto;
}

#CartItems {
	margin-top: 2em;	
	margin-bottom: 2em;
}

#cart-heading{
	padding-bottom: 1em;
}

#cart-item-empty {

}

#cart-header {
	margin-bottom: 2em;
	border-bottom: 1px solid #e4e4e4;
	justify-content: space-between;
}

@media only screen and (max-width: 775px) {
	#cart-header {
		display: none;
	}	
}
