.setup-entry-form-section {
  margin-bottom: 3em;
  border-bottom: 1px #d3d3d3 solid;
  padding-bottom: 2em;
}

.setup-entry-help {
	font-size: 0.9em;
	color: #737373;
	margin-top: 0.5em;
	margin-bottom: 0;
}

.small-select-dropdown {
	width: 15em;
}

#submit-flywheel-setup-btn {
	width: 30%;
}
