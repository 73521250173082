$height: 160px;
$width: 192px;
$hole-diameter: 33px;


#interactive-mosfet-board-plus-options {
	height: $height;
	width: $width;
}

.interactive-mosfet-board-plus-options-bg {
	height: $height;
	width: $width;

	background-size: $width $height;
}

#interactive-mosfet-board-plus-options-holes-container {
	margin: 0 auto;

	margin-top: 88px;
	width: 100%;
}

.mosfet-board-plus-interactive-options-selector {
	width: $hole-diameter;
	display: inline-block;
}

.mosfet-board-plus-interactive-options-selector:hover {
	cursor: pointer;
}

.mosfet-board-plus-interactive-options-selector:hover div{
	background-color: #dedede;
}

.interactive-mosfet-board-plus-options-holes {
	border-radius: 50%;

	height: $hole-diameter;
	width: $hole-diameter;

	/*margin-top: 100px;*/
}

/*#interactive-mosfet-board-plus-expandable-header-options-selector {
	height: 27px;
	width: 73px;

	margin-left: 6px;
}*/

/*#interactive-mosfet-board-2-jst-options-selector-container {
		margin-top: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector, #interactive-mosfet-board-2-right-jst-options-selector {
	height: 55px;
	width: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector {
	margin-right: 2px;
}

#interactive-mosfet-board-2-right-jst-options-selector {
	margin-left: 2px;
}
*/