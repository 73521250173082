#App {
  width: 100%;
  margin: 0 auto;
}

.switch {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 5em;
  /*margin-top: 6em;*/
}

@media screen and (max-width: 970px) {
  .switch {
    margin-top: 0;
  }

}

@media screen and (max-width: 500px) {
  #App {
    /*width: 90%;*/
  }
}













body, #root, html {
  margin: 0;
  padding: 0;
  font-family: 'Varela Round', sans-serif;
  /*height: 100%;*/
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, textarea {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

textarea {
  font-size: 1em;
}

#donate-button-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 3em;
}


.section, .section-center {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3em;
}

.section-center {
  text-align: center;
}

.section-header {
  text-decoration: none;
  font-weight: normal;
  margin-bottom: .5em;
}

.section-content {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 1em;
}

.section-img {
  height: 80%;
  width: 80%;
}

.section-button {
  width: 20%;
}

.invisible-text {
  visibility: hidden;
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  margin-top: 0;
  margin-bottom: .2em;
  font-size: 3em;
}

.flex-container-center, .flex-container-left, .flex-container , .flex-container-right, .flex-container-vertical-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-container-center, .flex-container-fully-centered {
  justify-content: center;
}

.flex-container-left {
  justify-content: left;
}

.flex-container-right {
  justify-content: flex-end;
}

.flex-container-vertical-center {
  align-items: center;
}

.flex-item {
  margin: 2em;
}

.center-img {
  margin: auto;
  display: block;
}

.center {
  margin: auto;
}

.fade {
  position: relative;
}

.fade:before {
  position: absolute;
  content:" ";
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: none;
  z-index:0;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.clock-icon {
  height: .8em;
  width: .8em;
}

.inline-container {
  display: inline-flex;
  width: 100%;
}

.right-align-text {
  text-align: right;
}

.left-align-text {
  text-align: left;
}

.center-text, .center-align-text {
  text-align: center;
}

.no-top-margin {
  margin-top: 0;
}

.horiz-fb {
  display: flex;
  flex-direction: row;
}

.right-fb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.grey-border-bottom {
  border-bottom: 1px solid #a8a8a8;
}

.text-right {
  text-align: right;
}

.no-underline-link {
  text-decoration: none;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.black-text {
  color: black;
}

.arrow-open, .arrow-closed {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-open {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow-closed {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.plus-btn, .minus-btn {
  border-radius: 50%;
  color: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-btn {
  background-color: #00b200;
}

.plus-btn:hover {
  background-color: #00cc00;
}

.minus-btn {
  background-color: #ff0000;
}

.minus-btn:hover {
  background-color: #ff4c4c;
}

.error-text, .error-txt {
  color: red;
}

.success-text {
  color: green;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.center-div {
  margin: 0 auto;
}

.shadow-border:hover {
  box-shadow:         3px 3px 5px 6px #ccc;
}

.flex-wrap {
  flex-wrap: wrap;
}

.button-style-1 {
  text-decoration: none;
  color: white;
  background-color: #a6a6a6;
  padding: 1em 2em;
  font-weight: normal;
  border-radius: 2px;
  cursor: pointer;
}

.button-style-1:hover {
  background-color: #bfbfbf;
}

.button-text {
  margin: 0;
  padding: 0;
}

.gone {
  display: none;
}

.exists {
  display: block;
}

.middot {
  padding: 0 0.5em;
  /* font-size: 1.5em !important;
  line-height: 1em; */
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.no-margin {
  margin: 0;
}





@media only screen and (min-width: 480px) {
  .section {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

@media only screen and (min-width: 720px) {
  .section {
    padding-left: 3em;
    padding-right: 3em;
  }
}

@media only screen and (max-width: 500px) {
  .switch {
    width: 90%;
  }
  
  /* .flex-container {
    justify-content: center;
  } */
}


  



































.App {
  text-align: center;
  height: 100%;
  width: 70%;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.overlay-txt {
  position: absolute; }

.invisible-txt, .txt-fade {
  visibility: hidden; }

.visible-txt, .txt-fade-after {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

@-webkit-keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.loading, .img-loading {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: loadingAnimation;
          animation-name: loadingAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.border-shadow {
  box-shadow: 0 0 10px #3f3f3f; }

.fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/ }

.fade-overlay:before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0; }

.fade-overlay:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.6); }

.txt-fade {
  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.txt-fade-after {
  opacity: 1; }

.selected {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.unselected {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.border-shadow {
  box-shadow: 2px 2px 3px #393939; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.exists {
  display: block; }

.does-not-exist {
  display: none; }

.navbar-link {
  color: #545454;
  font-size: 1.3em;
  margin: .5em;
  padding: .3em 0;

  text-decoration: none;
  /*text-transform: uppercase;*/
  transition: color .15s ease;

}

.selected-navbar-link {
  border-bottom: black 2px solid;
}

.navbar-link:hover {
  color: #939393;
}

@media screen and (max-width: 970px) {
  .navbar-link {
    font-size: 1.1em;
  }
}
#mobile-navbar-link-container {
  display: none;
}

@media screen and (max-width: 970px) {
  #mobile-navbar-link-container {
    width: 90%;

    margin: 0 auto;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  #navbar-link-container {
    display: none;
  }

}
.overlay-txt {
  position: absolute; }

.invisible-txt, .txt-fade {
  visibility: hidden; }

.visible-txt, .txt-fade-after {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

@-webkit-keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.loading {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: loadingAnimation;
          animation-name: loadingAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.border-shadow {
  box-shadow: 0 0 10px #3f3f3f; }

.fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/ }

.fade-overlay:before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0; }

.fade-overlay:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.6); }

.txt-fade {
  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.txt-fade-after {
  opacity: 1; }

.selected {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.unselected {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.border-shadow {
  box-shadow: 2px 2px 3px #393939; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.exists {
  display: block; }

.does-not-exist {
  display: none; }

#Navbar, #header {
  width: 100%;
  background-color: white;
  font-family: 'Raleway', sans-serif;
  border-bottom: 1px solid #e4e4e4;
  z-index: 1; }

#header {
  width: 90%;
  margin: 0 auto;
  border-bottom: none; }

#nav-cart-container, #logo {
  text-decoration: none;
  align-items: center;
  flex-grow: 1; }

#logo-img {
  max-height: 5em; }

#logo-heading, .nav-cart-text {
  font-weight: normal;
  color: #545454;
  margin: auto 0;
  margin-left: .5em; }

#logo-heading {
  font-size: 3em; }

#cart-img {
  max-width: 3em;
  max-height: 3em; }

#nav-cart-small-text {
  font-size: .7em;
  color: #939393; }

#nav-cart-med-text {
  font-size: 1.2em; }

#nav-cart-container:hover #nav-cart-med-text {
  color: #939393; }

/*navbar links*/
#navbar-link-container {
  height: 60%;
  margin: auto 0;
  align-items: flex-end;
  flex-grow: 2; }

#mobile-header {
  border-top: none; }

@media screen and (max-width: 970px) {
  #Navbar {
    position: static; }
  #mobile-header {
    border-top: 1px solid #e4e4e4;
    width: 100%; } }

@media screen and (max-width: 475px) {
  #header {
    display: flex;
    justify-content: center; }
  #nav-cart-container, #logo {
    flex-grow: 0; } }

#Carousel {
	overflow: hidden;
}

.fluid-carousel-big-img-container {
	width: auto;
	height: auto;
	position: relative;
}

.carousel-big-img {
	display:block;
    margin:auto;
    width: 100%
}

.carousel-small-imgs-container {
	display: flex;
	margin-top: .5em;
	justify-content: center;
}

.carousel-small-img {
	height: 3em;
	width: 3em;
	margin: .2em;

	cursor: pointer;

	border: none;
	transition: border 0.3s linear;

	opacity: 0.3;
	transition: opacity 0.3s ease-in-out;
	
}

.carousel-small-img:hover {
	opacity: 1;
}

.carousel-small-img-selected {
	opacity: 1;
	border: black 1px solid;
}

.hidden {
	visibility: hidden;
}

@media only screen and (max-width: 500px) {
	.carousel-big-img {
		/*max-width: 80%;*/
	}

	.carousel-small-img {
		height: 2em;
		width: 2em;
	}
}

/*@media only screen and (max-width: 400px) {
	.carousel-small-img {
		height: 2em;
		width: 2em;
	}
}
*/




#Home {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 2em;
}

#home-featured-text {
  margin-top: 3em;
}

#featured-content-container {
  margin: 0 auto;
  margin-top: 3em;
}

.feature-content-item {
  height: 20em;
  width: 20em;
  /*margin: 2em;*/
}

.featured-content-link {
  text-decoration: none;
  color: black;
}

.feature-content-item-img {
  height: 15em;
  width: 15em;
  display: block;
  margin: 0 auto;
}

.feature-content-item:hover .feature-content-item-img {
  box-shadow: 0 0 10px #3f3f3f;
}

.feature-content-item:hover .featured-title {
  color: #939393;
}

#Loading {
	width: 80%;
	height: 100%;
	margin: 0 auto;
}

#loading-container {
    width: 80%;
    margin: 0 auto;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    margin: 0 auto;
	margin-top: 5em;

}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.ContentBox1 {
	text-decoration: none;
	color: black;
}

.one-s-color {
  background-color: #ffe332; }

.two-s-color {
  background-color: lightblue; }

.three-s-color {
  background-color: #66b266; }

.four-s-color {
  background-color: #ffae19; }

.five-s-color {
  background-color: #ff1919; }

.one-s-triangle-color, .two-s-triangle-color, .three-s-triangle-color, .four-s-triangle-color, .five-s-triangle-color {
  border-bottom-style: solid; }

.one-s-triangle-color {
  border-bottom-color: #ffe332; }

.two-s-triangle-color {
  border-bottom-color: lightblue; }

.three-s-triangle-color {
  border-bottom-color: #66b266; }

.four-s-triangle-color {
  border-bottom-color: #ffae19; }

.five-s-triangle-color {
  border-bottom-color: #ff1919; }

.one-s-border-color {
  border-color: #ffe332; }

.two-s-border-color {
  border-color: lightblue; }

.three-s-border-color {
  border-color: #66b266; }

.four-s-border-color {
  border-color: #ffae19; }

.five-s-border-color {
  border-color: #ff1919; }

.one-s-font-color {
  color: #ffe332; }

.two-s-font-color {
  color: lightblue; }

.three-s-font-color {
  color: #66b266; }

.four-s-font-color {
  color: #ffae19; }

.five-s-font-color {
  color: #ff1919; }

.grey-bg {
  background-color: #545454; }

.grey-bg-hover {
  background-color: #939393; }

.light-grey-bg {
  background-color: #bebebe; }

.light-grey-bg-hover {
  background-color: #c9c9c9; }

.grey-border-bottom {
  border-bottom: 1px solid #e4e4e4; }

.super-light-grey-bg {
  background-color: #f6f6f6; }

.add-btn-color {
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  color: white !important; }

.add-btn-color:hover {
  background-color: #7cc67c !important; }

.remove-btn-color {
  background-color: #ff6666 !important;
  color: white !important; }

.remove-btn-color:hover {
  background-color: #ff8484 !important; }

.Sidebar {
  top: 80px;
  right: 0;
  height: 100%;
  width: 20em;
  position: fixed;
  background-color: #f7f7f7; }

@media screen and (max-width: 970px) {
  .Sidebar {
    top: 134px; } }

@media screen and (max-width: 500px) {
  .Sidebar {
    display: none; } }

.overlay-txt {
  position: absolute; }

.invisible-txt, .txt-fade {
  visibility: hidden; }

.visible-txt, .txt-fade-after {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

@-webkit-keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.loading {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: loadingAnimation;
          animation-name: loadingAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.border-shadow, .floating-btn {
  box-shadow: 0 0 10px #3f3f3f; }

.fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/ }

.fade-overlay:before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0; }

.fade-overlay:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.6); }

.txt-fade {
  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.txt-fade-after {
  opacity: 1; }

.selected {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.unselected {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.border-shadow, .floating-btn {
  box-shadow: 2px 2px 3px #393939; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.exists {
  display: block; }

.does-not-exist {
  display: none; }

.one-s-color {
  background-color: #ffe332; }

.two-s-color {
  background-color: lightblue; }

.three-s-color {
  background-color: #66b266; }

.four-s-color {
  background-color: #ffae19; }

.five-s-color {
  background-color: #ff1919; }

.one-s-triangle-color, .two-s-triangle-color, .three-s-triangle-color, .four-s-triangle-color, .five-s-triangle-color {
  border-bottom-style: solid; }

.one-s-triangle-color {
  border-bottom-color: #ffe332; }

.two-s-triangle-color {
  border-bottom-color: lightblue; }

.three-s-triangle-color {
  border-bottom-color: #66b266; }

.four-s-triangle-color {
  border-bottom-color: #ffae19; }

.five-s-triangle-color {
  border-bottom-color: #ff1919; }

.one-s-border-color {
  border-color: #ffe332; }

.two-s-border-color {
  border-color: lightblue; }

.three-s-border-color {
  border-color: #66b266; }

.four-s-border-color {
  border-color: #ffae19; }

.five-s-border-color {
  border-color: #ff1919; }

.one-s-font-color {
  color: #ffe332; }

.two-s-font-color {
  color: lightblue; }

.three-s-font-color {
  color: #66b266; }

.four-s-font-color {
  color: #ffae19; }

.five-s-font-color {
  color: #ff1919; }

.grey-bg {
  background-color: #545454; }

.grey-bg-hover {
  background-color: #939393; }

.light-grey-bg, .floating-btn {
  background-color: #bebebe; }

.light-grey-bg-hover, .floating-btn:hover {
  background-color: #c9c9c9; }

.grey-border-bottom {
  border-bottom: 1px solid #e4e4e4; }

.super-light-grey-bg {
  background-color: #f6f6f6; }

.add-btn-color {
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
  color: white !important; }

.add-btn-color:hover {
  background-color: #7cc67c !important; }

.remove-btn-color {
  background-color: #ff6666 !important;
  color: white !important; }

.remove-btn-color:hover {
  background-color: #ff8484 !important; }

.floating-btn {
  position: fixed;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 2; }

.HamburgerIcon {
  height: 100%;
  width: 100%; }

.hamburger-icon-bar {
  width: 35px;
  height: 3px;
  background-color: #393939;
  margin: 6px 0;
  transition: 0.4s; }

.animate-hamburger-icon .top-hamburger-icon-bar {
  -webkit-transform: rotate(-45deg) translate(-6px, 5px);
  transform: rotate(-45deg) translate(-6px, 5px); }

.animate-hamburger-icon .middle-hamburger-icon-bar {
  opacity: 0; }

.animate-hamburger-icon .bottom-hamburger-icon-bar {
  -webkit-transform: rotate(45deg) translate(-8px, -7px);
  transform: rotate(45deg) translate(-8px, -7px); }

.Modal-visible, .Modal-gone {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  background-color: white; }

.Modal-visible {
  opacity: 1; }

.Modal-gone {
  opacity: 0;
  z-index: -1; }

.modal-content-container-visible, .modal-content-container-gone {
  height: 100%;
  width: 100%;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s; }

.modal-content-container-visible {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px); }

.modal-content-container-gone {
  -webkit-transform: translate(0px, 40px);
          transform: translate(0px, 40px); }

#docs-sidebar {
	width: 17em;
}

#docs-sidebar-content {
	width: 70%;
	margin: 0 auto;
	margin-top: 3em;
	padding-right: 2em;
}

#docs-modal-content {
	width: 80%;
	margin: 0 auto; 
	margin-top: 5em;
}

.docs-modal-link {
	text-decoration: none;
	color: black;
	display: block;
	padding-left: .5em;
}

.docs-modal-link-main {
	/*font-size: 1.2em;*/
	font-weight: normal;
}

.docs-modal-link-selected {
	border-left: 2px solid black;
	font-weight: bold;
}

@media screen and (min-width: 969px) {
	#docs-floating-btn, #docs-modal-content, #docs-modal {
		visibility: hidden;
	}
}

@media screen and (max-width: 970px) {
	#docs-sidebar {
		display: none;
	}
}

#Docs {
	/*margin-right: 17em;*/
}

@media screen and (max-width: 970px) {
	#Docs {
		margin-right: 0;
	}
}

#doc-content {
	width: 80%;
	margin: 0 auto;
}

@media screen and (max-width: 970px) {
	#doc-content {
		width: 100%;
	}
}

#doc-content img, .doc-preview img {
  display:block;
  margin:auto;
  /*max-width: 80%;*/
  max-height: 50em;
}

.center-text-in-md {
	text-align: center;
}

.doc-preview {
	margin-bottom: 3em;
	border-bottom: 1px solid black;
}

.doc-preview img {
  max-width: 40%;
}

.doc-preview-link {
	color: black;
	text-decoration: none;
}

.doc-preview-header {
  border-bottom: 1px solid transparent;
  transition: border .15s ease;
}

.doc-preview-link:hover .doc-preview-header {
  color: #939393;
  text-decoration: underline;
}

.doc-preview-link:hover .doc-preview-md img {
  box-shadow: 0 0 10px #3f3f3f;
}

.doc-preview-clock-icon {
	display: inline-block;
	margin-left: 0!important;
	margin-right: 0!important;
}

.doc-preview-inline {
	font-size: .8em;
}

.doc-preview-date {
	margin-left: .4em;
}

.doc-preview-read-more {
  flex-grow: 100;
}

.doc-preview-link:hover .doc-preview-read-more {
  color: #939393;
  text-decoration: underline;
}


#DocsDetail {
	margin-bottom: 2em;
	width: 60%;
	margin: 0 auto;
	/*margin-right: 17em;*/
}

#DocsDetail img {
  display:block;
  margin:auto;
  max-width: 80%;
  max-height: 50em;
}

.docs-detail-title {
  text-decoration: underline;
}

.widget-btn {
	top: 80%;
	left: 80%;

	position: fixed;

	height: 75px;
	width: 75px;

	background-color: black;
	border-radius: 50%;

	cursor: pointer;
}

.scroll-up-btn {
	border: 3px;
}

@media screen and (max-width: 970px) {
	#DocsDetail {
		width: 90%;
	}
}

#Shop {
	margin-top: 2em;
	width: 100%;
}

#shop-items {
	width: 100%;
}

.shop-item {
	width: 20em;
	/*max-height: 25em;*/
 	margin: 2em;
}

.shop-item-link {
	text-decoration: none;
	color: black;
}

.shop-item-img {
	/*height: 100%;*/
	width: 100%;
	display: block;
	margin: auto;
}

.out-of-stock {
	opacity: 0.5;
}

.shop-item-link:hover .shop-item-img {
  box-shadow: 0 0 10px #3f3f3f;
}

.shop-item-title {
	margin-top: .4em;
	margin-bottom: .2em;
}

.disabled-link {
  pointer-events: none;
}

@media only screen and (max-width: 480px) {
	.shop-item {
		width: 8em;
	 	margin: 0.5em;
	}

	.shop-item-img {
	}
}
#ShopItem {
	width: 100%
}

#shop-item-content {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #a8a8a8;
}

#carousel-container {
	margin: 2em;
}

#shop-item-details {
	margin: 2em;
	flex-grow: 1;
}

.shop-item-info-heading {
	margin-bottom: .5em;
	font-weight: normal;
}

#shop-item-dropdown {
	margin-top: 0;
}

.shop-item-order-now {
	text-decoration: none;
	color: black;
	display: block;
	margin-top: 1em;
	transition: color .15s ease;
	border: black solid;
	padding: .2em;
	text-align: center;
	max-width: 10em;
}

.shop-item-order-now:hover  {
	color: #939393;
	border: #939393 solid;

}



@media only screen and (max-width: 950px) {
	#shop-item-content {
		flex-direction: column;
	}

	#carousel-container {
		margin-bottom: 0;
	}

	#shop-item-details {
		margin-top: 0;
	}

	#shop-item-description {
		width: 90%;
		margin: 0 auto;
	}
	
}

@media only screen and (max-width: 500px) {
	#shop-item-content {
		flex-direction: column;
		width: 100%;
	}

	#carousel-container {
		margin: 0;
		margin-top: 2em;
	}

	#shop-item-details {
		margin-top: 0;
	}

}

.Dropdown {
	height: 3em;
	min-width: 5em;
	border-radius: 4px;
}

@media only screen and (max-width: 480px) {
	.Dropdown {
		min-width: 3em;
	}
}

.checkboxes-title {
  margin-bottom: .2em;
}

/* Customize the label (the checkbox) */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1em;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* The container */
.radio-btn-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-btn-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-btn-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-btn-container:hover input ~ .radio-btn-checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-btn-container input:checked ~ .radio-btn-checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-btn-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-btn-container input:checked ~ .radio-btn-checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-btn-container .radio-btn-checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
#more-options-btn {
	align-items: center;
}

#more-options-btn:hover {
	cursor: pointer;
}

.dropdown-caret {
	border-bottom: 0 solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top-style: solid;
  border-top-width: 6px;
  content: "";
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
  color: #757575;
}
#interactive-mosfet-board-2-options {
  height: 150px;
  width: 150px; }

.interactive-mosfet-board-2-options-bg {
  height: 150px;
  width: 150px;
  background-size: 150px 150px; }

#interactive-mosfet-board-2-expandable-header-options-selector {
  height: 27px;
  width: 73px;
  margin-left: 6px; }

#interactive-mosfet-board-2-jst-options-selector-container {
  margin-top: 70px; }

#interactive-mosfet-board-2-left-jst-options-selector, #interactive-mosfet-board-2-right-jst-options-selector {
  height: 55px;
  width: 70px; }

#interactive-mosfet-board-2-left-jst-options-selector {
  margin-right: 2px; }

#interactive-mosfet-board-2-right-jst-options-selector {
  margin-left: 2px; }

#interactive-mosfet-board-plus-options {
  height: 160px;
  width: 192px; }

.interactive-mosfet-board-plus-options-bg {
  height: 160px;
  width: 192px;
  background-size: 192px 160px; }

#interactive-mosfet-board-plus-options-holes-container {
  margin: 0 auto;
  margin-top: 88px;
  width: 100%; }

.mosfet-board-plus-interactive-options-selector {
  width: 33px;
  display: inline-block; }

.mosfet-board-plus-interactive-options-selector:hover {
  cursor: pointer; }

.mosfet-board-plus-interactive-options-selector:hover div {
  background-color: #dedede; }

.interactive-mosfet-board-plus-options-holes {
  border-radius: 50%;
  height: 33px;
  width: 33px;
  /*margin-top: 100px;*/ }

/*#interactive-mosfet-board-plus-expandable-header-options-selector {
	height: 27px;
	width: 73px;

	margin-left: 6px;
}*/
/*#interactive-mosfet-board-2-jst-options-selector-container {
		margin-top: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector, #interactive-mosfet-board-2-right-jst-options-selector {
	height: 55px;
	width: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector {
	margin-right: 2px;
}

#interactive-mosfet-board-2-right-jst-options-selector {
	margin-left: 2px;
}
*/

.overlay-txt {
  position: absolute; }

.invisible-txt, .txt-fade {
  visibility: hidden; }

.visible-txt, .txt-fade-after {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

@-webkit-keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.loading {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: loadingAnimation;
          animation-name: loadingAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.border-shadow {
  box-shadow: 0 0 10px #3f3f3f; }

.fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/ }

.fade-overlay:before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0; }

.fade-overlay:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.6); }

.txt-fade {
  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.txt-fade-after {
  opacity: 1; }

.selected {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.unselected {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.border-shadow {
  box-shadow: 2px 2px 3px #393939; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.exists {
  display: block; }

.does-not-exist {
  display: none; }

#item-options-container {
  padding-bottom: 1em; }

#item-options, #more-options-container-expanded, #more-options-container-collapsed {
  margin-left: 2em; }

.option-set-title {
  font-weight: normal; }

.option-checkbox-label {
  font-size: .9em; }

#more-options-container-expanded {
  max-height: 10000px;
  transition: max-height .3s ease-in-out;
  -webkit-transition: max-height .3s ease-in-out; }

#more-options-container-collapsed {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  -webkit-transition: max-height .3s ease-in-out; }

.interactive-options-bg {
  position: absolute;
  z-index: -1;
  opacity: 0.4;
  background-repeat: no-repeat; }

.interactive-options-selector {
  opacity: .8;
  background-color: #b3b3b3; }

.interactive-options-selector:hover {
  cursor: pointer;
  background-color: #dedede; }

#Shop-item-description {
  width: 60%;
  margin: 0 auto; }

#shop-item-description-markdown-container {
  margin-bottom: 4em; }

.markdown-container img {
  max-width: 90%;
  display: block;
  margin: auto; }

@media only screen and (max-width: 950px) {
  #Shop-item-description {
    width: 90%;
    margin: 0 auto; } }


.CartItem {
	margin-bottom: 1em;
	padding-bottom: .5em;
	border-bottom: 1px solid #e4e4e4;
}

.cart-item-container {
	justify-content: space-between;
}

.product-index-container {
	width: 2em;
}

.remove-from-cart-btn {

}

.cart-item-link-to-product {
	text-decoration: none;
}

.cart-item-img {
	max-width: 7em;
	max-height: 7em;
	/*margin-right: 1em;*/
	display:block;
	margin: auto;
	margin-bottom: .2em; 
}

.cart-item-first-row {
	margin-top: 0;
	margin-bottom: .4em;
}

.cart-item-title {
	color: black;
	transition: color .15s ease;
}

.cart-item-link-to-product:hover .cart-item-title {
	color: #939393;
}

.cart-item-column, .cart-item-column-first {
	margin-right: 2em;
	width: 10em;
}

.cart-item-column-first {
	display: flex;
}

.in-stock {
	color: green;
	margin-top: 0;
	margin-bottom: 0.5em;
}

.options-container {

}

.options-text {
	font-size: .9em;
	margin-bottom: 0;
}

.cart-item-price-txt, .cart-item-quantity {
	color: black;
}

.cart-item-price-val {
	color: #990000;
	font-weight: bold;
	margin-bottom: 0;
	font-size: 1.2em;
}

.listing-cost-each {
	text-align: right;
	margin-top: .2em;
	color: #939393;
	font-size: .9em;

	/*right justify*/
	direction: rtl;
	text-align: justify;
	margin-left: @center-justification;
	margin-right: @center-justification;
}

@media only screen and (max-width: 480px) {
	.cart-item-column {
		margin-right: 0em;
	}

	.cart-item-column-first {
		margin-right: 1em;
	}
}

.CartItem {
	margin-bottom: 1em;
	padding-bottom: .5em;
	border-bottom: 1px solid #e4e4e4;
	justify-content: space-between;
}

.cart-item-product-info {
	width: 70%;
}

.cart-item-link-to-product {
	text-decoration: none;
}

.cart-item-img {
	width: 10em;
	height: 10em;
	margin-right: 1em;
}

.cart-item-title {
	color: black;
	font-weight: none;
	margin: 0;
	margin-bottom: .5em;
}

.cart-item-link-to-product:hover .cart-item-title {
	color: #939393;
}

.options-container {
	margin-bottom: .5em;
}

.options-text {
	color: #663096;
}

.remove-from-cart-btn {
	padding: .5em;
	width: 5em;
}

.cart-item-qty-wrapper {
	margin-right: 2em;
	width: 4em;
}

.cart-item-price-wrapper {
	margin-left: 2em;
	width: 4em;
}

.cart-item-price-txt, .cart-item-quantity {
	color: black;
}

.cart-item-price-val {
	color: #990000;
	font-weight: bold;
	margin-bottom: 0;
	font-size: 1.2em;
}

.listing-cost-each {
	text-align: right;
	margin-top: .2em;
	color: #939393;
	font-size: .9em;

	text-align: right;
}

@media only screen and (max-width: 780px) {
	.CartItem {
		justify-content: center;
	}	
}

@media only screen and (max-width: 770px) {
	.cart-item-product-info {
		justify-content: center;
	}	
}


#cart-costs {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 auto;
}

.cart-cost-price-val, .cart-cost-price-description {
	margin-top: .3em;
	margin-bottom: .3em;
}

.cart-cost-price-val {
	color: #990000;
	padding-left: .2em;
}
#CartItems, .cart-item {
	margin: 0 auto;
}

#CartItems {
	margin-top: 2em;	
	margin-bottom: 2em;
}

#cart-heading{
	padding-bottom: 1em;
}

#cart-item-empty {

}

#cart-header {
	margin-bottom: 2em;
	border-bottom: 1px solid #e4e4e4;
	justify-content: space-between;
}

@media only screen and (max-width: 775px) {
	#cart-header {
		display: none;
	}	
}

.non-expanding-form-input, .form-input {
	display: block;
	height: 1em;
	border-radius: 3px;
	width: 70%;
}

.non-expanding-form-input {
	resize: none;
	line-height: 1em;
	padding: .5em;
}

.form-submit-btn  {
	text-decoration: none;
	border: none;
	cursor: pointer;
}

@media only screen and (max-width: 775px) {
	.non-expanding-form-input, .form-input {
		width: 100%;
	}	
}
#Checkout {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.red-asterisk {
	color: red;
	display: inline;
}

#order-notes-input {
	height: 6em;
}
#ComingSoon {
	text-align: center;
}
#NotFound {
	text-align: center;
}

#not-found-title {
	margin-bottom: 2em;
}
#Payment {
	width: 80%;
	margin: 0 auto;
}

#payment-heading {
	margin-bottom: 0;
}

#paypal-btn-container {
	width: 85%;
	margin: 0 auto;
	margin-top: 2em;
	margin-bottom: 1em;
	text-align: right;	
}

#payment-failure-text {
	margin-bottom: 2em;
}
#PaymentSuccess {
	text-align: center;
}

.payment-success-text {
	margin-bottom: 3em;
}
#Footer {
	margin: 0 auto;
	/*border-top: 1px solid #a8a8a8;*/
	padding-top: 5em;
	padding-bottom: 2em;
	background-color: #303841;
	color: white;
}

#footer-content {
	width: 80%;
}

.footer-links-container {
	width: 15em;
	margin-bottom: 3em;
}

.footer-links-title {
	margin-bottom: .4em;
	margin-top: 0;
	font-weight: normal;
}

.footer-link {
	text-decoration: none;
}

.footer-link-text {
	margin: .3em 0;
	color: white;
}

.footer-link-text:hover {
	color: #cccccc;
	/*color: #545454;*/
	/*color: black;*/
}

#copyright {
	/*color: black;*/
}

@media only screen and (max-width: 620px) {
  	.footer-links-title, .footer-link {
		text-align: center;
	}
}
#Message {
	text-align: center;
	background-color: #f6f6f6;
	padding-top: 1em;
	padding-bottom: 1em;
	width: 100%;

  /*position: fixed;*/
}

#message-wrapper {
	width: 80%;
}

#message-title {

}

#message-text {
}
#Cart {
	width: 80%;
	margin: 0 auto;
}

#proceed-to-checkout-btn {
	
}

#CustomOrders {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

#custom-order-info {
	margin-left: 2em;
}

.custom-orders-basic-info-title, .custom-orders-basic-info-text {
	margin-bottom: 0;
}

.custom-orders-basic-info-text {
	margin-top: 1.3em; 
	margin-left: .2em;
}

.markdown-container {
	margin-left: 2em;
}

#shop-item-order-now {
	text-decoration: none;
	color: black;
	display: block;
	margin-top: 1em;
	transition: color .15s ease;
	border: black solid;
	padding: .2em;
	text-align: center;
	max-width: 10em;
}

#shop-item-order-now:hover {
	color: #939393;
	border: #939393 solid;

}

#Login {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 2em;
}

#invalid-credentials {
	color: red;
}
#changelog-items {
  width: 80%; }

.change-container {
  padding-bottom: 1em;
  border-bottom: 1px solid #e4e4e4; }

.change-date {
  width: 8em; }

.change-date-txt {
  font-size: 1.2em; }

.change-category-title {
  margin-bottom: .2em; }

.change-description {
  width: 80%; }

@media screen and (max-width: 600px) {
  #changelog-items {
    width: 90%; }
  .change-description {
    width: 100%; } }

#About {

}

#about-img {
  width: 25em;
  margin: 2em;
}

#about-txt {
  width: 25em;
  margin: 2em;
}

@media only screen and (max-width: 480px) {
	#About {
		justify-content: center;
	}

	#about-img {
	  width: 90%;
	  display: block;
	  margin: 2em auto;
	}
}
#Tools {

}

.tools-content-box {
	margin: 2em;
	width: 20em;
}

.tools-content-box-img {
	width: 100%;
}	

.tools-content-box-text-container {
	width: 90%;
	margin: 0 auto;
}

#HowLiPoCheckerWorks {
	margin-bottom: 3em;
}

.lipo-checker-title-txt {
	margin-bottom: .2em;
}

#lipo-checker-steps-container {

}

.lipo-checker-instructions-accordian {
	width: 100%;
}

@media only screen and (max-width: 740px) {

}

#LiPoCheckerMotorSelector {
	/*margin-bottom: 3em;*/
}	

#motor-selection-form {

}

.lipo-checker-motor-selector-dropdown, .qty-selector-dropdown, .cell-count-selector-dropdown {
	margin: 0 2em;
	margin-bottom: 1em;
}

.lipo-checker-motor-selector-dropdown {
	width: 17em;
}

.motor-selector-placeholder {
    color: #333333;
    flex-grow: 2;
}

.motor-selector-dropdown-control {
	background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
}

.motor-selector-dropdown-menu {
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0,0,0,.06);
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    z-index: 10;
    padding-left: 5px;
    padding-right: 5px;
}

.Dropdown-title {
	font-weight: 700;
	font-size: 1.1em;
	margin: .2em 0;
}

.Dropdown-option {
	box-sizing: border-box;
    color: #333333;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.Dropdown-option:hover {
	background-color: #f2f2f2;
}

.qty-selector-dropdown {
	width: 5em;
}

.cell-count-selector-dropdown {
	width: 10em;
}

#selected-motors-container {

}

#selected-motors-table-captions {
	border-bottom: 1px solid black;
	margin-bottom: 1em;
	padding-left: 2em;
	font-size: 1.2em;
}

.selected-motors-table-captions, .selected-motor-txt {
	text-align: center;
	margin-left: 1em;
}

#selected-motors-table-captions-name {
	width: 30%;
}

#selected-motors-table-captions-qty, #selected-motors-table-captions-cellCount, #selected-motors-table-captions-stallCurrent {
	width: 10%;
}

#selected-motors {

}

.selected-motor {
	display: flex;
	align-items: center;
}

.selected-motor-txt {
	
}

.selected-motor-name {
	width: 30%;
}

.selected-motor-qty, .selected-motor-cellCount, .selected-motor-stallCurrent {
	width: 10%;
} 

#current-calculations {
	border-top: 1px solid black;
	margin-top: 2em;
}

.current-calculations-txt {
	margin: .5em 0;
}

#total-combined-stall-current {
	font-size: 1.20em;
	font-weight: 700;
}

.selected-motors-overvolted-txt {
	color: #FF1919;
}

.selected-motors-undervolted-txt {
	color: #9bc2cf;
}	

#LiPoCheckerResults {

}

#lipo-result-good, #lipo-result-bad {
	font-weight: normal;
	font-size: 1.5em;
}

.results-table-title {
	font-size: 1.2em;
}

.results-table {
	width: 95%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.slider-val-text {
	margin-bottom: 2.5em;
	font-size: 1.2em;
	color: #f7941d;
}
#LiPoCalculator {
	/*margin-bottom: 3em;*/
}

#battery-selector-sliders-container {
	/*justify-content: center;*/
}

#battery-selector-dropdown-wrapper {
	width: 100%;
}

.battery-selector-slider-container {
	width: 80%;
	margin-bottom: 3em;
}

#battery-selector-calculate-btn {
	border-radius: 20px;
	border-width: 0;
	height: 2em;
	font-size: 1.5em;
	margin-right: 1em;
	margin-top: .5em;
	margin-bottom: .5em;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: .2em .7em;
	background-color: #ee8822;
	color: white;
}

#battery-selector-calculate-btn:hover {
	background-color: #f19f4e;
}

#battery-selector-calculate-btn-txt {
	margin: 0;
}

#LiPoCheckerHeadroomSelect {
	
}

#headroom-slider-container {
	width: 80%;
	margin: 0 auto;
}

#headroom-slider-heading {

}
#LiPoChecker {

}

.lipo-checker-section {
	margin-bottom: 5em;
}

.lipo-checker-slider-container {
	width: 80%;
	margin-bottom: 3em;
}

#calculate-btn {
	border-radius: 20px;
	border-width: 0;
	height: 2em;
	font-size: 1.5em;
	margin-right: 1em;
	margin-top: .5em;
	margin-bottom: .5em;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: .2em .7em;
	background-color: #ee8822;
	color: white;
}

#calculate-btn:hover {
	background-color: #f19f4e;
}

#calculate-btn-txt {
	margin: 0;
}
.volting-shape {
  border: #939393 1px solid; }

.volting-shape, .volting-shape-triangle {
  opacity: .7; }

.volting-shape-triangle {
  border-left-color: transparent;
  border-left-style: solid;
  border-right-color: transparent;
  border-right-style: solid;
  border-bottom-style: solid; }

.text-container-inside-volting-shape {
  position: absolute;
  top: 0;
  width: 100%; }

#MotorChartLegend {

}

.legend-shape {
	margin: .5em 1em;		/*first top and botton, then left and right*/
	margin-top: 1em;
}

.legend-label {
	margin: auto 0;
	text-align: center;
}
#MotorChart {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.recharts-wrapper {
	margin: 0 auto;
}

.chart-instructions {
	margin: 0 auto;
	width: 90%;
	padding-bottom: 1em;
}


#MotorInfo {
	
}

#MotorChartMotorInfo {
	/*background-color: #f4f4f4;*/
	padding-bottom: 1em;
	width: 100%;
	margin: 0 auto;
	flex-wrap: nowrap;
}

.motor-info-box {
		padding: 1em;
	}

#motor-info-title {
	margin-bottom: .4em;
}

.motor-info-img {
	width: 20em;
	width: 20em;
	margin: 0 auto;
}

.specs-container {
	width: 20em;
	padding-bottom: 1em;
	margin-bottom: 2em;
	border-bottom: solid 1px #e4e4e4;
}

.motor-info-title-2 {
	margin-top: .3em;
	margin-bottom: .3em;
}

.shop-info {
	margin-bottom: 2em;
}

.shop-info:hover {
	background-color: #e4e4e4;
}

.shop-info-flex-item {
	min-width: 12em;
	max-width: 25em;

	margin-left: .5em;
	margin-top: .5em;
}

.shop-info-logo {
	max-height: 5em;
	max-width: 10em;
}

@media only screen and (max-width: 775px) {
	#MotorChartMotorInfo {
		flex-wrap: wrap;
	}
}




.overlay-txt, .selectable-motor-box-overlay-container {
  position: absolute; }

.invisible-txt, .txt-fade, .selectable-motor-box-overlay-txt {
  visibility: hidden; }

.visible-txt, .txt-fade-after, .selectable-motor-box-fade-overlay:hover .selectable-motor-box-overlay-txt {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

@-webkit-keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes loadingAnimation {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.loading {
  -webkit-animation-duration: 1.25s;
          animation-duration: 1.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: loadingAnimation;
          animation-name: loadingAnimation;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative; }

.border-shadow, .selectable-motor-box:hover, .selected-selectable-motor-box:hover, .unselected-selectable-motor-box:hover {
  box-shadow: 0 0 10px #3f3f3f; }

.fade-overlay, .selectable-motor-box-fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/ }

.fade-overlay:before, .selectable-motor-box-fade-overlay:before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 0; }

.fade-overlay:hover:before, .selectable-motor-box-fade-overlay:hover:before {
  display: block;
  background-color: rgba(0, 0, 0, 0.6); }

.txt-fade, .selectable-motor-box-overlay-txt {
  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.txt-fade-after, .selectable-motor-box-fade-overlay:hover .selectable-motor-box-overlay-txt {
  opacity: 1; }

.selected, .unselected-selectable-motor-box:hover {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.unselected, .unselected-selectable-motor-box {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s; }

.border-shadow, .selectable-motor-box:hover, .selected-selectable-motor-box:hover, .unselected-selectable-motor-box:hover {
  box-shadow: 2px 2px 3px #393939; }

.visible {
  visibility: visible; }

.invisible {
  visibility: hidden; }

.exists {
  display: block; }

.does-not-exist {
  display: none; }

.overlay-txt, .selectable-motor-box-overlay-container {
  position: absolute; }

.invisible-txt, .txt-fade, .selectable-motor-box-overlay-txt {
  visibility: hidden; }

.visible-txt, .txt-fade-after, .selectable-motor-box-fade-overlay:hover .selectable-motor-box-overlay-txt {
  visibility: visible; }

.gone-txt {
  display: none; }

.inline-p {
  display: inline;
  margin: 0; }

.inline-txt, .inline-text, .inline-p, .middot {
  display: inline;
  overflow-wrap: break-word; }

.text-with-newlines {
  white-space: pre-wrap; }

.no-margin-bottom {
  margin-bottom: 0; }

.right-text-align {
  text-align: right; }

.selectable-motor-box, .selected-selectable-motor-box, .unselected-selectable-motor-box {
  margin: 1em;
  cursor: pointer;
  position: relative; }

.selected-selectable-motor-box {
  border: 1px #939393 solid; }

.selectable-motor-box-img {
  margin: 0 auto;
  width: 10em;
  height: 10em; }

.selectable-motor-box-overlay-container {
  top: 0;
  left: 0;
  /*padding-top: .5em;*/ }

.selectable-motor-box-overlay-title {
  display: none; }

.selectable-motor-box-overlay-txt {
  color: white;
  margin: .2em;
  font-size: .8em; }

.selectable-motor-box-name {
  width: 10em;
  margin-bottom: .2em; }

.selectable-motor-box-cost {
  font-size: .8em; }

@media only screen and (max-width: 694px) {
  /*	.selectable-motor-box, .selected-selectable-motor-box, .unselected-selectable-motor-box {
		margin: .5em;
	}*/
  .selectable-motor-box-img {
    width: 8em;
    height: 8em; }
  .selectable-motor-box-name {
    width: 8em; } }




.flywheel-setup-picker-entry-form-cell-count-slider-container {
  margin-bottom: 2em;
  width: 95%; }

.stage-selection-entry-form {
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 1em;
  padding-top: 0;
  margin-top: 2em; }

.stage-entry-form-item {
  width: 100%;
  margin: 0.5em; }

.setup-entry-checkbox-label {
  line-height: 1em !important;
  font-size: 1em !important;
  font-weight: normal !important; }

.setup-entry-form-btn {
  margin-top: 2em !important; }

@media (min-width: 1600px) {
  .stage-entry-form-item {
    width: 23%; } }

.setup-entry-form-section {
  margin-bottom: 3em;
  border-bottom: 1px #d3d3d3 solid;
  padding-bottom: 2em; }

.setup-entry-help {
  font-size: 0.9em;
  color: #737373;
  margin-top: 0.5em;
  margin-bottom: 0; }

.small-select-dropdown {
  width: 15em; }

#submit-flywheel-setup-btn {
  width: 30%; }

.setup-stage-body {
  margin-left: 1em; }

.setup-stage-spec {
  margin-bottom: 1em; }

.setup-stage-key-text {
  font-size: 0.8em;
  margin-right: 0.5em; }

.setup-stage-value-text {
  font-weight: 500;
  font-size: 1.1em; }

.setup-stage-icons-half-container {
  width: 50%; }

.setup-stage-icon-container {
  height: 2.5em; }

.setup-stage-icon-text {
  margin: 0.5em;
  margin-right: 0;
  font-size: 0.7em;
  width: 75%; }

.setup-comment {
  margin-bottom: 1em; }

.setup-comment .info p {
  font-size: 0.8em; }

.setup-comment .comment {
  margin: 0; }

.setup-comments .comments-display-container {
  margin-top: 1em; }

.setup-comments {
  width: 90%;
  margin-bottom: 2em; }

.setup-comments .comments-display-container {
  margin-bottom: 2em; }

.setup-comments .comment-entry .comment-submit-btn {
  text-transform: capitalize; }

.setup-comments .comment-entry .entry-item {
  width: 95%; }

.setup-comments .comment-entry .comment-entry-info-container {
  margin-top: 0.5em; }

.setup-comments .comment-entry .comment-entry-info {
  width: 85%;
  margin-left: 0.5em;
  margin-top: 0; }

.SetupDisplay-card, .SetupDisplay-card-modal {
  width: 20em;
  margin: 1em;
  border-style: solid;
  border-radius: 5px;
  background-color: white; }

.SetupDisplay-card-modal {
  width: 60%;
  overflow: auto; }

@media only screen and (max-width: 720px) {
  .SetupDisplay-card-modal {
    width: 80%; } }

.setup-display-card-header {
  padding: 1em 0; }

.setup-display-card-header-text {
  color: white;
  margin: 0; }

.setup-display-card-subheader, .setup-display-card-footer-bar {
  padding: 0.5em 1em;
  font-size: 0.8em; }

.setup-display-card-body-section {
  width: 90%;
  margin: 1em auto; }

.setup-display-card-footer-bar {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.setup-display-card-footer-bar .item {
  flex-grow: 1; }

.flywheel-setup-section, .flywheel-setup-section {
  border-radius: 5px;
  border: 1px solid #d3d3d3; }

.FlywheelSetup:hover, .flywheel-setup-section:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149); }

.flywheel-setup-section {
  margin: 1em 0;
  padding: 0 1em; }

.flywheel-setup-stage {
  width: 95%;
  margin: 2em auto;
  padding-bottom: 1em;
  border-bottom: 1px solid #d3d3d3; }

.flywheel-setup-stage-stage-number {
  width: 20%; }

.flywheel-setup-stage-stage-parts {
  width: 75%;
  padding-left: 1em;
  border-left: 1px solid #d3d3d3; }

.flywheel-setup-stage-stage-part {
  margin-bottom: 0.75em; }

.voting-arrow:hover {
  cursor: pointer; }

#SetupEntry {
  padding-bottom: 1.5em;
  margin-top: 2em;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149); }

#setup-entry-form {
  margin: 0 auto;
  width: 90%; }

.setup-entry-text-field {
  width: 100%; }

.setup-entry-text-field, .select-entry {
  margin-top: 2em !important; }

#Filter {
  padding: 1em;
  padding-bottom: 2em;
  margin-top: 2em; }

.FilterDropdown {
  margin: 1em; }

.filter-dropdown {
  margin: 0 auto; }

.FilterDropdown .filter-dropdown-title {
  margin-bottom: .5em; }

.FilterDropdown .small-select-dropdown {
  margin-top: .5em !important; }

.filter-section-text {
  margin-bottom: 0;
  margin-top: 2em; }

#Filter #expand-trigger {
  margin-top: 3em; }




#Contact {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

#ContactSuccess {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}
#StoreToolsLogin{
	/*text-align: center;*/ 
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

#invalid-credentials-text {
	color: red;
}
