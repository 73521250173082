.setup-comments {
  width: 90%;
  margin-bottom: 2em;

}

.setup-comments .comments-display-container {
  margin-bottom: 2em;

}

.setup-comments .comment-entry .comment-submit-btn {
  text-transform: capitalize;
}

.setup-comments .comment-entry .entry-item {
  width: 95%;
}

.setup-comments .comment-entry .comment-entry-info-container {
  margin-top: 0.5em;
}

.setup-comments .comment-entry .comment-entry-info {
  width: 85%;
  margin-left: 0.5em;
  margin-top: 0;
}