$height: 150px;
$width: 150px;


#interactive-mosfet-board-2-options {
	height: $height;
	width: $width;
}

.interactive-mosfet-board-2-options-bg {
	height: $height;
	width: $width;

	background-size: $width $height;
}

#interactive-mosfet-board-2-expandable-header-options-selector {
	height: 27px;
	width: 73px;

	margin-left: 6px;
}

#interactive-mosfet-board-2-jst-options-selector-container {
		margin-top: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector, #interactive-mosfet-board-2-right-jst-options-selector {
	height: 55px;
	width: 70px;
}

#interactive-mosfet-board-2-left-jst-options-selector {
	margin-right: 2px;
}

#interactive-mosfet-board-2-right-jst-options-selector {
	margin-left: 2px;
}
