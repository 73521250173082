#Shop {
	margin-top: 2em;
	width: 100%;
}

#shop-items {
	width: 100%;
}

.shop-item {
	width: 20em;
	/*max-height: 25em;*/
 	margin: 2em;
}

.shop-item-link {
	text-decoration: none;
	color: black;
}

.shop-item-img {
	/*height: 100%;*/
	width: 100%;
	display: block;
	margin: auto;
}

.out-of-stock {
	opacity: 0.5;
}

.shop-item-link:hover .shop-item-img {
  box-shadow: 0 0 10px #3f3f3f;
}

.shop-item-title {
	margin-top: .4em;
	margin-bottom: .2em;
}

.disabled-link {
  pointer-events: none;
}

@media only screen and (max-width: 480px) {
	.shop-item {
		width: 8em;
	 	margin: 0.5em;
	}

	.shop-item-img {
	}
}