#LiPoChecker {

}

.lipo-checker-section {
	margin-bottom: 5em;
}

.lipo-checker-slider-container {
	width: 80%;
	margin-bottom: 3em;
}

#calculate-btn {
	border-radius: 20px;
	border-width: 0;
	height: 2em;
	font-size: 1.5em;
	margin-right: 1em;
	margin-top: .5em;
	margin-bottom: .5em;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	padding: .2em .7em;
	background-color: #ee8822;
	color: white;
}

#calculate-btn:hover {
	background-color: #f19f4e;
}

#calculate-btn-txt {
	margin: 0;
}