.overlay-txt {
	position: absolute;
}

.invisible-txt {
	visibility: hidden;
}

.visible-txt {
	visibility: visible;
}

.gone-txt {
	display: none;
}

.inline-p {
	display: inline;
	margin: 0;
}

// This is separate from inline-p because I made inline-p earlier and didn't 
// know of the inline-block solution
.inline-txt, .inline-text, .inline-p, .middot {
	display: inline;
	overflow-wrap: break-word;

}	

.text-with-newlines {
	white-space:pre-wrap;
}

.no-margin-bottom {
	margin-bottom: 0;
}

.right-text-align {
	text-align: right;
}