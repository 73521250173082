#Shop-item-description {
	width: 60%;
	margin: 0 auto;
}

#shop-item-description-markdown-container {
	margin-bottom: 4em;
}

.markdown-container img {
	max-width: 90%;
	display:block;
	margin:auto;
} 

@media only screen and (max-width: 950px) {
	#Shop-item-description {
		width: 90%;
		margin: 0 auto;
	}
	
}
