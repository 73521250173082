#cart-costs {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: 0 auto;
}

.cart-cost-price-val, .cart-cost-price-description {
	margin-top: .3em;
	margin-bottom: .3em;
}

.cart-cost-price-val {
	color: #990000;
	padding-left: .2em;
}