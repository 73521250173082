#LiPoCheckerMotorSelector {
	/*margin-bottom: 3em;*/
}	

#motor-selection-form {

}

.lipo-checker-motor-selector-dropdown, .qty-selector-dropdown, .cell-count-selector-dropdown {
	margin: 0 2em;
	margin-bottom: 1em;
}

.lipo-checker-motor-selector-dropdown {
	width: 17em;
}

.motor-selector-placeholder {
    color: #333333;
    flex-grow: 2;
}

.motor-selector-dropdown-control {
	background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
}

.motor-selector-dropdown-menu {
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0,0,0,.06);
    overflow-y: auto;
    position: absolute;
    background-color: #fff;
    z-index: 10;
    padding-left: 5px;
    padding-right: 5px;
}

.Dropdown-title {
	font-weight: 700;
	font-size: 1.1em;
	margin: .2em 0;
}

.Dropdown-option {
	box-sizing: border-box;
    color: #333333;
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.Dropdown-option:hover {
	background-color: #f2f2f2;
}

.qty-selector-dropdown {
	width: 5em;
}

.cell-count-selector-dropdown {
	width: 10em;
}

#selected-motors-container {

}

#selected-motors-table-captions {
	border-bottom: 1px solid black;
	margin-bottom: 1em;
	padding-left: 2em;
	font-size: 1.2em;
}

.selected-motors-table-captions, .selected-motor-txt {
	text-align: center;
	margin-left: 1em;
}

#selected-motors-table-captions-name {
	width: 30%;
}

#selected-motors-table-captions-qty, #selected-motors-table-captions-cellCount, #selected-motors-table-captions-stallCurrent {
	width: 10%;
}

#selected-motors {

}

.selected-motor {
	display: flex;
	align-items: center;
}

.selected-motor-txt {
	
}

.selected-motor-name {
	width: 30%;
}

.selected-motor-qty, .selected-motor-cellCount, .selected-motor-stallCurrent {
	width: 10%;
} 

#current-calculations {
	border-top: 1px solid black;
	margin-top: 2em;
}

.current-calculations-txt {
	margin: .5em 0;
}

#total-combined-stall-current {
	font-size: 1.20em;
	font-weight: 700;
}

.selected-motors-overvolted-txt {
	color: #FF1919;
}

.selected-motors-undervolted-txt {
	color: #9bc2cf;
}	
