#docs-sidebar {
	width: 17em;
}

#docs-sidebar-content {
	width: 70%;
	margin: 0 auto;
	margin-top: 3em;
	padding-right: 2em;
}

#docs-modal-content {
	width: 80%;
	margin: 0 auto; 
	margin-top: 5em;
}

.docs-modal-link {
	text-decoration: none;
	color: black;
	display: block;
	padding-left: .5em;
}

.docs-modal-link-main {
	/*font-size: 1.2em;*/
	font-weight: normal;
}

.docs-modal-link-selected {
	border-left: 2px solid black;
	font-weight: bold;
}

@media screen and (min-width: 969px) {
	#docs-floating-btn, #docs-modal-content, #docs-modal {
		visibility: hidden;
	}
}

@media screen and (max-width: 970px) {
	#docs-sidebar {
		display: none;
	}
}
