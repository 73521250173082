#Message {
	text-align: center;
	background-color: #f6f6f6;
	padding-top: 1em;
	padding-bottom: 1em;
	width: 100%;

  /*position: fixed;*/
}

#message-wrapper {
	width: 80%;
}

#message-title {

}

#message-text {
}