#Carousel {
	overflow: hidden;
}

.fluid-carousel-big-img-container {
	width: auto;
	height: auto;
	position: relative;
}

.carousel-big-img {
	display:block;
    margin:auto;
    width: 100%
}

.carousel-small-imgs-container {
	display: flex;
	margin-top: .5em;
	justify-content: center;
}

.carousel-small-img {
	height: 3em;
	width: 3em;
	margin: .2em;

	cursor: pointer;

	border: none;
	transition: border 0.3s linear;

	opacity: 0.3;
	transition: opacity 0.3s ease-in-out;
	
}

.carousel-small-img:hover {
	opacity: 1;
}

.carousel-small-img-selected {
	opacity: 1;
	border: black 1px solid;
}

.hidden {
	visibility: hidden;
}

@media only screen and (max-width: 500px) {
	.carousel-big-img {
		/*max-width: 80%;*/
	}

	.carousel-small-img {
		height: 2em;
		width: 2em;
	}
}

/*@media only screen and (max-width: 400px) {
	.carousel-small-img {
		height: 2em;
		width: 2em;
	}
}
*/



