.navbar-link {
  color: #545454;
  font-size: 1.3em;
  margin: .5em;
  padding: .3em 0;

  text-decoration: none;
  /*text-transform: uppercase;*/
  transition: color .15s ease;

}

.selected-navbar-link {
  border-bottom: black 2px solid;
}

.navbar-link:hover {
  color: #939393;
}

@media screen and (max-width: 970px) {
  .navbar-link {
    font-size: 1.1em;
  }
}