$fade-time: .5s;
$transform-time: .5s;

.Modal-visible, .Modal-gone {
	height: 100%;
	width: 100%;
	position: fixed;
  z-index: 1;
  left: 0;
  top: 0;

  transition: opacity $fade-time;
  -webkit-transition: opacity $fade-time;

  background-color: white;
}

.Modal-visible {
	opacity: 1;
}

.Modal-gone {
	opacity: 0;
	z-index: -1;
	// display: none;
}

.modal-content-container-visible, .modal-content-container-gone {
	height: 100%;
	width: 100%;
	transition: transform $transform-time ease 0s;
}

.modal-content-container-visible {
	transform: translate(0px, 0px);
}

.modal-content-container-gone {
	transform: translate(0px, 40px);
}