.non-expanding-form-input, .form-input {
	display: block;
	height: 1em;
	border-radius: 3px;
	width: 70%;
}

.non-expanding-form-input {
	resize: none;
	line-height: 1em;
	padding: .5em;
}

.form-submit-btn  {
	text-decoration: none;
	border: none;
	cursor: pointer;
}

@media only screen and (max-width: 775px) {
	.non-expanding-form-input, .form-input {
		width: 100%;
	}	
}