#LiPoCheckerHeadroomSelect {
	
}

#headroom-slider-container {
	width: 80%;
	margin: 0 auto;
}

#headroom-slider-heading {

}