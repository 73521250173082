#HowLiPoCheckerWorks {
	margin-bottom: 3em;
}

.lipo-checker-title-txt {
	margin-bottom: .2em;
}

#lipo-checker-steps-container {

}

.lipo-checker-instructions-accordian {
	width: 100%;
}

@media only screen and (max-width: 740px) {

}
