.setup-comment {
  margin-bottom: 1em;
}

.setup-comment .info p {
  font-size: 0.8em;
}

.setup-comment .info .username {

}

.setup-comment .comment {
  // margin-top: 0.5em;
  margin: 0;
}