$borderRadius: 5px;


.SetupDisplay-card, .SetupDisplay-card-modal {
	width: 20em;
	margin: 1em;

	// border-width: 1.5px;
	border-style: solid;
	border-radius: $borderRadius;

	background-color: white;

}

.SetupDisplay-card-modal {
	width: 60%;
	overflow: auto;
}

@media only screen and (max-width: 720px) {
  .SetupDisplay-card-modal {
		width: 80%;
	}
}

.setup-display-card-header {
	padding: 1em 0;
	// padding;
}

.setup-display-card-header-text {
	color: white;
	margin: 0;
}

.setup-display-card-subheader, .setup-display-card-footer-bar {
	padding: 0.5em 1em;

	font-size: 0.8em;
	
}

.setup-display-card-body-section {
	width: 90%;
	margin: 1em auto;

}

.setup-display-card-footer-bar {
	border-bottom-left-radius: $borderRadius;
	border-bottom-right-radius: $borderRadius;	
}

.setup-display-card-footer-bar .item {
	flex-grow: 1;
}














.flywheel-setup-section, .flywheel-setup-section {
	border-radius: 5px;
	border: 1px solid #d3d3d3;
}

.FlywheelSetup:hover, .flywheel-setup-section:hover {
	box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149);
}


.flywheel-setup-section {
	margin: 1em 0;
	padding: 0 1em;
}

.flywheel-setup-stage {
	width: 95%;
	margin: 2em auto;

	padding-bottom: 1em;

	border-bottom: 1px solid #d3d3d3;
}

.flywheel-setup-stage-stage-number {
	width: 20%;
}

.flywheel-setup-stage-stage-parts {
	width: 75%;

	padding-left: 1em;
	border-left: 1px solid #d3d3d3
}

.flywheel-setup-stage-stage-part {
	margin-bottom: 0.75em;
}

.voting-arrow:hover {
	cursor: pointer;
}