#DocsDetail {
	margin-bottom: 2em;
	width: 60%;
	margin: 0 auto;
	/*margin-right: 17em;*/
}

#DocsDetail img {
  display:block;
  margin:auto;
  max-width: 80%;
  max-height: 50em;
}

.docs-detail-title {
  text-decoration: underline;
}

.widget-btn {
	top: 80%;
	left: 80%;

	position: fixed;

	height: 75px;
	width: 75px;

	background-color: black;
	border-radius: 50%;

	cursor: pointer;
}

.scroll-up-btn {
	border: 3px;
}

@media screen and (max-width: 970px) {
	#DocsDetail {
		width: 90%;
	}
}
