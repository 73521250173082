#more-options-btn {
	align-items: center;
}

#more-options-btn:hover {
	cursor: pointer;
}

.dropdown-caret {
	border-bottom: 0 solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top-style: solid;
  border-top-width: 6px;
  content: "";
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
  color: #757575;
}