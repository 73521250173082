#App {
  width: 100%;
  margin: 0 auto;
}

.switch {
  width: 85%;
  margin: 0 auto;
  margin-bottom: 5em;
  /*margin-top: 6em;*/
}

@media screen and (max-width: 970px) {
  .switch {
    margin-top: 0;
  }

}

@media screen and (max-width: 500px) {
  #App {
    /*width: 90%;*/
  }
}













body, #root, html {
  margin: 0;
  padding: 0;
  font-family: 'Varela Round', sans-serif;
  /*height: 100%;*/
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, textarea {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

textarea {
  font-size: 1em;
}

#donate-button-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 3em;
}


.section, .section-center {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3em;
}

.section-center {
  text-align: center;
}

.section-header {
  text-decoration: none;
  font-weight: normal;
  margin-bottom: .5em;
}

.section-content {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 1em;
}

.section-img {
  height: 80%;
  width: 80%;
}

.section-button {
  width: 20%;
}

.invisible-text {
  visibility: hidden;
}

.bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  margin-top: 0;
  margin-bottom: .2em;
  font-size: 3em;
}

.flex-container-center, .flex-container-left, .flex-container , .flex-container-right, .flex-container-vertical-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-container-center, .flex-container-fully-centered {
  justify-content: center;
}

.flex-container-left {
  justify-content: left;
}

.flex-container-right {
  justify-content: flex-end;
}

.flex-container-vertical-center {
  align-items: center;
}

.flex-item {
  margin: 2em;
}

.center-img {
  margin: auto;
  display: block;
}

.center {
  margin: auto;
}

.fade {
  position: relative;
}

.fade:before {
  position: absolute;
  content:" ";
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: none;
  z-index:0;
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.clock-icon {
  height: .8em;
  width: .8em;
}

.inline-container {
  display: inline-flex;
  width: 100%;
}

.right-align-text {
  text-align: right;
}

.left-align-text {
  text-align: left;
}

.center-text, .center-align-text {
  text-align: center;
}

.no-top-margin {
  margin-top: 0;
}

.horiz-fb {
  display: flex;
  flex-direction: row;
}

.right-fb {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.grey-border-bottom {
  border-bottom: 1px solid #a8a8a8;
}

.text-right {
  text-align: right;
}

.no-underline-link {
  text-decoration: none;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.black-text {
  color: black;
}

.arrow-open, .arrow-closed {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow-open {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow-closed {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.plus-btn, .minus-btn {
  border-radius: 50%;
  color: white;
  width: 35px;
  height: 35px;
  cursor: pointer;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-btn {
  background-color: #00b200;
}

.plus-btn:hover {
  background-color: #00cc00;
}

.minus-btn {
  background-color: #ff0000;
}

.minus-btn:hover {
  background-color: #ff4c4c;
}

.error-text, .error-txt {
  color: red;
}

.success-text {
  color: green;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.center-div {
  margin: 0 auto;
}

.shadow-border:hover {
  -moz-box-shadow:    3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  box-shadow:         3px 3px 5px 6px #ccc;
}

.flex-wrap {
  flex-wrap: wrap;
}

.button-style-1 {
  text-decoration: none;
  color: white;
  background-color: #a6a6a6;
  padding: 1em 2em;
  font-weight: normal;
  border-radius: 2px;
  cursor: pointer;
}

.button-style-1:hover {
  background-color: #bfbfbf;
}

.button-text {
  margin: 0;
  padding: 0;
}

.gone {
  display: none;
}

.exists {
  display: block;
}

.middot {
  padding: 0 0.5em;
  /* font-size: 1.5em !important;
  line-height: 1em; */
}

.pointer-on-hover:hover {
  cursor: pointer;
}

.no-margin {
  margin: 0;
}





@media only screen and (min-width: 480px) {
  .section {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

@media only screen and (min-width: 720px) {
  .section {
    padding-left: 3em;
    padding-right: 3em;
  }
}

@media only screen and (max-width: 500px) {
  .switch {
    width: 90%;
  }
  
  /* .flex-container {
    justify-content: center;
  } */
}


  



































.App {
  text-align: center;
  height: 100%;
  width: 70%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
