#About {

}

#about-img {
  width: 25em;
  margin: 2em;
}

#about-txt {
  width: 25em;
  margin: 2em;
}

@media only screen and (max-width: 480px) {
	#About {
		justify-content: center;
	}

	#about-img {
	  width: 90%;
	  display: block;
	  margin: 2em auto;
	}
}