#LiPoCheckerResults {

}

#lipo-result-good, #lipo-result-bad {
	font-weight: normal;
	font-size: 1.5em;
}

.results-table-title {
	font-size: 1.2em;
}

.results-table {
	width: 95%;
	margin: 0 auto;
	margin-bottom: 3em;
}
