#Home {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 2em;
}

#home-featured-text {
  margin-top: 3em;
}

#featured-content-container {
  margin: 0 auto;
  margin-top: 3em;
}

.feature-content-item {
  height: 20em;
  width: 20em;
  /*margin: 2em;*/
}

.featured-content-link {
  text-decoration: none;
  color: black;
}

.feature-content-item-img {
  height: 15em;
  width: 15em;
  display: block;
  margin: 0 auto;
}

.feature-content-item:hover .feature-content-item-img {
  box-shadow: 0 0 10px #3f3f3f;
}

.feature-content-item:hover .featured-title {
  color: #939393;
}
