.volting-shape {
	border: #939393 1px solid;
}

.volting-shape, .volting-shape-triangle {
	opacity: .7;
}

.volting-shape-triangle {
	border-left-color: transparent;
	border-left-style: solid;

	border-right-color: transparent;
	border-right-style: solid;
	
	border-bottom-style: solid;
}

.text-container-inside-volting-shape {
	position: absolute;
	top: 0;
	width: 100%;
}