.flywheel-setup-picker-entry-form-cell-count-slider-container {
	margin-bottom: 2em;
	width: 95%;
}

.stage-selection-entry-form {
	border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 1em;
  padding-top: 0;
  margin-top: 2em;
}

.stage-entry-form-item {
	width: 100%;
	margin: 0.5em
}

.setup-entry-checkbox-label {
	line-height: 1em !important;
	font-size: 1em !important;
	font-weight: normal !important;
}

.setup-entry-form-btn {
	margin-top: 2em !important;
}



@media (min-width: 1600px) {
  .stage-entry-form-item {
		width: 23%;
	}
}