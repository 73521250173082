#Filter {
  padding: 1em;
  padding-bottom: 2em;
  margin-top: 2em;
}

.FilterDropdown {
  margin: 1em;
}

.filter-dropdown {
  margin: 0 auto;
}

.FilterDropdown .filter-dropdown-title{
  margin-bottom: .5em;
}

.FilterDropdown .small-select-dropdown{
  margin-top: .5em !important;
}

.filter-section-text {
  margin-bottom: 0;
  margin-top: 2em;
}

#checkbox-filter-container {
  
}



#Filter #expand-trigger {
  margin-top: 3em;
}