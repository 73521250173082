@import 'styles/animations.scss';
@import 'styles/text.scss';

$desktop-size: 10em;
$mobile-size: 8em;

.selectable-motor-box, .selected-selectable-motor-box, .unselected-selectable-motor-box {
	margin: 1em;
	cursor: pointer;
	position: relative;
}

.unselected-selectable-motor-box {
	@extend .unselected;
}

.selected-selectable-motor-box {
	border: 1px #939393 solid;
}

.selectable-motor-box:hover, .selected-selectable-motor-box:hover, .unselected-selectable-motor-box:hover {
	@extend .border-shadow;
}

.unselected-selectable-motor-box:hover {
	@extend .selected;
}

.selectable-motor-box-fade-overlay {
	@extend .fade-overlay
}

.selectable-motor-box-img {
	margin: 0 auto;
	width: $desktop-size;
	height: $desktop-size;
}

.selectable-motor-box-overlay-container {
	@extend .overlay-txt;

	top: 0;
	left: 0;

	/*padding-top: .5em;*/
}

.selectable-motor-box-overlay-title {
	display: none;
}

.selectable-motor-box-overlay-txt {
	@extend .txt-fade;
	color: white;
	margin: .2em;
	font-size: .8em;
}

.selectable-motor-box-fade-overlay:hover .selectable-motor-box-overlay-txt {
	@extend .txt-fade-after;
}

.selectable-motor-box-name {
	width: $desktop-size;
	margin-bottom: .2em;
}

.selectable-motor-box-cost {
	font-size: .8em;
}


@media only screen and (max-width: 694px) {
/*	.selectable-motor-box, .selected-selectable-motor-box, .unselected-selectable-motor-box {
		margin: .5em;
	}*/

  .selectable-motor-box-img {
  	width: $mobile-size;
  	height: $mobile-size;
  }

  .selectable-motor-box-name {
		width: $mobile-size;
	}
}