.setup-stage-body {
	margin-left: 1em;
}

.setup-stage-spec {
	margin-bottom: 1em;
}

.setup-stage-key-text {
	font-size: 0.8em;
	margin-right: 0.5em;
}

.setup-stage-value-text {
	font-weight: 500;
	font-size: 1.1em;
}

.setup-stage-icons-half-container {
	width: 50%;
}

.setup-stage-icon-container {
	height: 2.5em;
}

.setup-stage-icon-text {
	margin: 0.5em;
	margin-right: 0;

	font-size: 0.7em;
	
	width: 75%;
	// height: 2em;
}