#Payment {
	width: 80%;
	margin: 0 auto;
}

#payment-heading {
	margin-bottom: 0;
}

#paypal-btn-container {
	width: 85%;
	margin: 0 auto;
	margin-top: 2em;
	margin-bottom: 1em;
	text-align: right;	
}

#payment-failure-text {
	margin-bottom: 2em;
}