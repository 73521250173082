#Checkout {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.red-asterisk {
	color: red;
	display: inline;
}

#order-notes-input {
	height: 6em;
}