@import 'styles/animations.scss';

#item-options-container {
	padding-bottom: 1em;
}

#item-options, #more-options-container-expanded, #more-options-container-collapsed {
	margin-left: 2em;
}

.option-set-title {
	font-weight: normal;
}

.option-checkbox-label {
	font-size: .9em;
}

#more-options-container-expanded {
	max-height: 10000px;

	transition: max-height .3s ease-in-out;
  -webkit-transition: max-height .3s ease-in-out;
}

#more-options-container-collapsed {
	max-height: 0px;
	overflow: hidden;

	transition: max-height .3s ease-in-out;
  -webkit-transition: max-height .3s ease-in-out;
}

.interactive-options-bg {
	position: absolute;

	z-index: -1;

	opacity: 0.4;
	background-repeat: no-repeat;
}

.interactive-options-selector {
	opacity: .8;
	background-color: #b3b3b3;
}

.interactive-options-selector:hover {
	cursor: pointer;
	background-color: #dedede;
}