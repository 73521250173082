@import 'styles/text.scss';


@keyframes loadingAnimation {
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.loading {
	animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: loadingAnimation;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

.border-shadow {
    box-shadow: 0 0 10px #3f3f3f;
}

.fade-overlay {
  position: relative;
  /*margin: 2em;*/
  /*padding: 1em;*/
}

.fade-overlay:before{
  position: absolute;
  content:" ";
  top:0;
  left:0;
  width:100%;
  height:100%;
  display: none;
  z-index:0;
}

.fade-overlay:hover:before{
  display: block;
  background-color: rgba(0, 0, 0, 0.6);
}

.txt-fade {
  @extend .invisible-txt;

  opacity: 0;
  transition: opacity .3s;
  -webkit-transition: opacity .3s;
}

.txt-fade-after {
  @extend .visible-txt;

  opacity: 1;
}

.selected {
  opacity: 1;
  transition: opacity .3s;
  -webkit-transition: opacity .3s;
}

.unselected {
  opacity: 0.5;
  transition: opacity .3s;
  -webkit-transition: opacity .3s;
}

.border-shadow {
  box-shadow: 2px 2px 3px #393939;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.exists {
  display: block;
}

.does-not-exist {
  display: none;
}