#ShopItem {
	width: 100%
}

#shop-item-content {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #a8a8a8;
}

#carousel-container {
	margin: 2em;
}

#shop-item-details {
	margin: 2em;
	flex-grow: 1;
}

.shop-item-info-heading {
	margin-bottom: .5em;
	font-weight: normal;
}

#shop-item-dropdown {
	margin-top: 0;
}

.shop-item-order-now {
	text-decoration: none;
	color: black;
	display: block;
	margin-top: 1em;
	transition: color .15s ease;
	border: black solid;
	padding: .2em;
	text-align: center;
	max-width: 10em;
}

.shop-item-order-now:hover  {
	color: #939393;
	border: #939393 solid;

}



@media only screen and (max-width: 950px) {
	#shop-item-content {
		flex-direction: column;
	}

	#carousel-container {
		margin-bottom: 0;
	}

	#shop-item-details {
		margin-top: 0;
	}

	#shop-item-description {
		width: 90%;
		margin: 0 auto;
	}
	
}

@media only screen and (max-width: 500px) {
	#shop-item-content {
		flex-direction: column;
		width: 100%;
	}

	#carousel-container {
		margin: 0;
		margin-top: 2em;
	}

	#shop-item-details {
		margin-top: 0;
	}

}
