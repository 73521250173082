@import 'styles/animations.scss';
@import 'styles/colors.scss';

.floating-btn {
	position: fixed;
	border-radius: 50%;

	width: 70px;
	height: 70px;

	bottom: 30px;
	right: 30px;
	cursor: pointer;

	z-index: 2;

	@extend .border-shadow;
	@extend .light-grey-bg;
}

.floating-btn:hover {
	@extend .light-grey-bg-hover;
}
