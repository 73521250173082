#MotorChartMotorInfo {
	/*background-color: #f4f4f4;*/
	padding-bottom: 1em;
	width: 100%;
	margin: 0 auto;
	flex-wrap: nowrap;
}

.motor-info-box {
		padding: 1em;
	}

#motor-info-title {
	margin-bottom: .4em;
}

.motor-info-img {
	width: 20em;
	width: 20em;
	margin: 0 auto;
}

.specs-container {
	width: 20em;
	padding-bottom: 1em;
	margin-bottom: 2em;
	border-bottom: solid 1px #e4e4e4;
}

.motor-info-title-2 {
	margin-top: .3em;
	margin-bottom: .3em;
}

.shop-info {
	margin-bottom: 2em;
}

.shop-info:hover {
	background-color: #e4e4e4;
}

.shop-info-flex-item {
	min-width: 12em;
	max-width: 25em;

	margin-left: .5em;
	margin-top: .5em;
}

.shop-info-logo {
	max-height: 5em;
	max-width: 10em;
}

@media only screen and (max-width: 775px) {
	#MotorChartMotorInfo {
		flex-wrap: wrap;
	}
}
