// Colors that represent different cell counts
$oneSColor: #ffe332;
$twoSColor: rgb(173, 216, 230);
$threeSColor: rgb(102, 178, 102);
$fourSColor: rgb(255, 174, 25);
$fiveSColor: rgb(255, 25, 25);


.one-s-color {
	background-color: $oneSColor;
}

.two-s-color {
	background-color: $twoSColor;
}

.three-s-color {
	background-color: $threeSColor;
}

.four-s-color {
  background-color: $fourSColor;
}

.five-s-color {
  background-color: $fiveSColor;
}


.one-s-triangle-color, .two-s-triangle-color, .three-s-triangle-color, .four-s-triangle-color, .five-s-triangle-color {
  border-bottom-style: solid;
}

.one-s-triangle-color {
  border-bottom-color: $oneSColor;
}

.two-s-triangle-color {
  border-bottom-color: $twoSColor;
}

.three-s-triangle-color {
  border-bottom-color: $threeSColor;
}

.four-s-triangle-color {
  border-bottom-color: $fourSColor;
}

.five-s-triangle-color {
  border-bottom-color: $fiveSColor;
}



.one-s-border-color {
	border-color: $oneSColor;
}

.two-s-border-color {
	border-color: $twoSColor;
}

.three-s-border-color {
	border-color: $threeSColor;
}

.four-s-border-color {
  border-color: $fourSColor;
}

.five-s-border-color {
  border-color: $fiveSColor;
}



.one-s-font-color {
	color: $oneSColor;
}

.two-s-font-color {
	color: $twoSColor;
}

.three-s-font-color {
	color: $threeSColor;
}

.four-s-font-color {
  color: $fourSColor;
}

.five-s-font-color {
  color: $fiveSColor;
}







.grey-bg {
	background-color: #545454;
}

.grey-bg-hover {
	background-color: #939393;
}


.light-grey-bg {
	background-color: #bebebe;
}

.light-grey-bg-hover {
	background-color: #c9c9c9;
}


.grey-border-bottom {
  border-bottom: 1px solid #e4e4e4;
}


.super-light-grey-bg {
  background-color: #f6f6f6;
}



.add-btn-color {
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;

  color: white !important;
}

.add-btn-color:hover {
  background-color: #7cc67c !important;
}

.remove-btn-color {
  background-color: #ff6666 !important;
  // border-color: #4cae4c !important;

  color: white !important;
}

.remove-btn-color:hover {
  background-color: #ff8484 !important;
}

