#Tools {

}

.tools-content-box {
	margin: 2em;
	width: 20em;
}

.tools-content-box-img {
	width: 100%;
}	

.tools-content-box-text-container {
	width: 90%;
	margin: 0 auto;
}
