.Dropdown {
	height: 3em;
	min-width: 5em;
	border-radius: 4px;
}

@media only screen and (max-width: 480px) {
	.Dropdown {
		min-width: 3em;
	}
}
