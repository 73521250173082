#MotorChart {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 3em;
}

.recharts-wrapper {
	margin: 0 auto;
}

.chart-instructions {
	margin: 0 auto;
	width: 90%;
	padding-bottom: 1em;
}

