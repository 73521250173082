#MotorChartLegend {

}

.legend-shape {
	margin: .5em 1em;		/*first top and botton, then left and right*/
	margin-top: 1em;
}

.legend-label {
	margin: auto 0;
	text-align: center;
}