#SetupEntry {
	// padding: 1.5em;
	padding-bottom: 1.5em;

	margin-top: 2em;

	width: 100%;
	// margin: 0 auto;

	border-radius: 5px;
	border: 1px solid #d3d3d3;
	box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 2px 6px 2px rgba(60,64,67,0.149);
}

#setup-entry-form {
	margin: 0 auto;
	width: 90%;
}

.setup-entry-text-field {
	width: 100%;
}

.setup-entry-text-field, .select-entry {
	margin-top: 2em !important;
}
